import React from "react"

import useDeviceDetect from "../../../service/useDeviceDetect"

const ParaImageWithTitleDesc = (props) => {

    const { isMobile } = useDeviceDetect()

    const defaultImageSrc = isMobile === true ? "https://via.placeholder.com/310x210" : "https://via.placeholder.com/1110x340"

    const { content } = props
    const title = content && content.title ? content.title : ''
    const desc = content && content?.description?.processed ? content.description.processed : '<p></p>'
    const align = content && content.alignment ? content.alignment : 'right'

    const imageSrc = isMobile === true ? content.relationships?.mobile_image?.uri?.url : content.relationships?.image?.uri?.url
    const altText = content?.image?.alt || "Hinduja Hospital"
    return (
        <div className={`guide_principle_row ${align}_content`}>
            <div className="row">
            <div className="col-12">
                <div className="guide_principle_wrap ">
                    <div className="guide_principle_img">
                        <picture className="banner_img">
                            {
                                imageSrc ?
                                <img
                                    src={imageSrc}
                                    alt={altText}
                                />
                                : 
                                <>
                                    <source media="(min-width:1024px)" srcSet={defaultImageSrc} />
                                    <source media="(min-width:480px)" srcSet={defaultImageSrc} />
                                    <source media="(min-width:300px)" srcSet={defaultImageSrc} />
                                    <img className="lazyload"  src={defaultImageSrc} alt={altText} />                            
                                </>
                            }
                        </picture>
                    </div>
                    <div className="guide_principle_content">
                        <h3>{title}</h3>
                        <div dangerouslySetInnerHTML={{ __html: desc }}></div>
                    </div>
                </div>
            </div>
            </div>
        </div> 
    )
}

export default ParaImageWithTitleDesc
