import React, { useState } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"
import Schema from "../components/addon/schema"
import PageHeader from "../components/pageSections/pageHeader"
import ParagraphText from "../components/pageSections/paragraphText"
import ParaImageWithTitleDesc from "../components/pageSections/paraImageWithTitleDesc"
import ParaTitleWithDescription from "../components/pageSections/titleWithDescription"

const OurGuidingPrinciples = props => {
  const { data } = props
  let pageTitle = ""
  let pageInfo = {}
  let components = []
  let metaTags = []
  let schema = []
  let canonicalUrl = {}
  if (data.allNodeTemplatePages && data.allNodeTemplatePages.edges) {
    pageInfo = data.allNodeTemplatePages.edges[0]
    pageTitle = pageInfo?.node?.title ? pageInfo.node.title : ""
    components = pageInfo?.node?.relationships?.components
      ? pageInfo.node.relationships.components
      : []
    metaTags = pageInfo?.node?.metatags ? pageInfo.node.metatags : []
    schema = pageInfo?.node?.relationships?.field_seo_schema ? pageInfo?.node?.relationships?.field_seo_schema : []
    canonicalUrl = pageInfo?.node?.metatags_on_page || {}
  }
  console.log('pageInfo==',pageInfo)

  let paragraph__section,
    paragraph__title_and_description,
    paragraph__image_with_title_and_description
  if (components) {
    paragraph__section = components.find(
      c => c.__typename === "paragraph__section"
    )
    paragraph__title_and_description = components.find(
      c => c.__typename === "paragraph__title_and_description"
    )
    paragraph__image_with_title_and_description = components.filter(
      c => c.__typename === "paragraph__image_with_title_and_description"
    )
  }

  return (
    <Layout>
      <Meta
        files={{
          js: [],
          css: ["/assets/css/about-guiding-principle.css"],
        }}
        tags={{metaTags,canonicalUrl}}
      />
      <Schema schema={schema} />
      <main className="innerpage guidelinePrinciples">
        <section className="section_bg pt-first-section pb-section">
          <div className="container">
            <div className="section-head mb-0">
              <PageHeader headTag="h1" title={pageTitle} />
              {components && components[0] && components[0]?.htmlText && (
                <ParagraphText content={components[0]} />
              )}
            </div>
            {paragraph__section &&
              paragraph__section.relationships &&
              paragraph__section.relationships.components && (
                <div className="row guidelineCardWrap">
                  {paragraph__section.relationships.components.map((c, ind) => {
                    const img = c?.relationships?.image?.uri?.url
                    const alt = c.image.alt
                    return (
                      <div class="col-md-12 col-lg-6">
                        <div class="guidelineCard">
                          <div class="imageSec">
                            {img && (
                              <img
                                src={img}
                                alt={alt.length ? alt : `image${ind}`}
                              />
                            )}
                          </div>
                          <div class="textSec">
                            <h3>{c.title}</h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: c?.description?.processed,
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}

            {paragraph__title_and_description && (
              <div className="guidelineCardWrap">
                <ParaTitleWithDescription
                  head={3}
                  content={paragraph__title_and_description}
                />
              </div>
            )}
          </div>
        </section>
        <section className="section_bg guide_principle_inner">
          <div className="container">
            {paragraph__image_with_title_and_description &&
              paragraph__image_with_title_and_description.map((el, index) => {
                return <ParaImageWithTitleDesc key={index} content={el} />
              })}
          </div>
        </section>
      </main>
    </Layout>
  )
}
export const query = graphql`
  query aboutGuidingPrinciplesPage {
    allNodeTemplatePages(
      filter: { path: { alias: { regex: "/about-our-guiding-principles$/" } } }
    ) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          relationships{
            field_seo_schema{
                field_seo_title
                field_seo_schema_text_area
            }
          }
          metatags: metatag_normalized {
            attributes {
              content
              name
              property
            }
          }
          metatags_on_page: field_meta_tags{
            canonical_url
          }
          relationships {
            components: field_component_type {
              __typename
              ...ParagraphSection1
              ...ParagraphTitleAndDescription
              ...ParagraphImageWithTitleAndDescription1
            }
          }
        }
      }
    }
  }
  fragment ParagraphSection1 on paragraph__section {
    id
    name: field_name
    relationships {
      components: field_components {
        __typename
        ...ParagraphImageWithTitleAndDescription1
      }
    }
  }
  fragment ParagraphTitleAndDescription on paragraph__title_and_description {
    id
    field_title
    text: field_description {
      processed
    }
  }
  fragment ParagraphImageWithTitleAndDescription1 on paragraph__image_with_title_and_description {
    id
    title: field_title
    description: field_content {
      processed
    }
    field_text {
      processed
    }
    image: field_image {
      alt
    }
    alignment: field_alignment
    relationships {
      image: field_image {
        id
        uri {
          value
          url
        }
      }
    }
  }
`
export default OurGuidingPrinciples
